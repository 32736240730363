<template>
  <v-card flat>
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon color="primary" :disabled="saveEnabled" @click="saveItems()">
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
      <v-btn icon color="primary" :disabled="saveEnabled" @click="clearItems()">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table :headers="headers" :items="items" sort-by="order" :items-per-page="5" class="mb-5">
        <template v-slot:item.action="{ item }">
          <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        
        <template v-slot:item.notes="{ item }">
          <div v-html="getNotes(item.notes)"></div>
        </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "ContactUsList",

  props: {
    title: {
      type: String,
      required: true,
    },
    collection: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data: () => ({}),

  computed: {
    headers() {
      let list = [
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone", width: 130 },
        { text: "Email", value: "email" },
        { text: "Message", value: "message" },
        { text: "Source", value: "source" },
        { text: "Date", value: "createdDate" },
        { text: "", value: "action", sortable: false, width: 100 },
      ];

      return list;
    },
    saveEnabled() {
      return this.items == null || this.items.length == 0;
    },
  },

  methods: {
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch("deleteList", {
            collection: this.collection,
            item: item,
          })
          .then(
            () => {
              const index = this.items.indexOf(item);
              this.items.splice(index, 1);
            },
            (error) => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      }
    },
    getNotes(notes) {
      if (notes != null && notes != undefined) {
        return notes.replace(/(?:\r\n|\r|\n)/g, "<br>");
      } else {
        return "";
      }
    },
    saveItems() {
      let content = this.getContent(this.items, "\t");

      navigator.clipboard.writeText(content).then(
        () => {
          const notification = {
            show: true,
            result: true,
            message: "Copying to clipboard was successful",
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error("response: ", error);
        }
      );
    },
    getContent(items, delimiter) {
      if (items == null || delimiter == null) return;

      let content = "";

      items.forEach((item) => {
        let message = item.message;

        if (message != null && message != undefined) {
          message = item.message.replace(/(\r\n|\n|\r)/gm, "");
        }

        const arr = [
          item.firstName,
          item.lastName,
          item.phone,
          item.email,
          "",
          message,
          item.source,
          item.createdDate,
        ];
        content += arr.join(delimiter) + "\r\n";
      });

      return content;
    },
    clearItems() {
      if (confirm("Are you sure you want to delete ALL records?")) {
        this.$store.dispatch("deleteAllContactUs").then((response) => {
          const notification = {
            show: true,
            result: true,
            message: response,
          };

          this.$store.dispatch("showSnackbar", notification);
        });
      }
    },
  },
};
</script>

<style scoped>
.padding-input {
  padding: 0 10px;
}
</style>
<template>
  <div>
    <ContactUsList title="Contact Us" collection="contact-us" :items= contactUsList />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ContactUsList from "@/components/admin/ContactUsList";

export default {
  name: "ContactUs",

  components: {
    ContactUsList
  },

  computed: {
    ...mapGetters(["contactUsList"])
  },

  methods: {
    ...mapActions(["getContactUsList"])
  },

  mounted() {
    this.getContactUsList();
  }
};
</script>
